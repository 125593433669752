import React, { useEffect, useState } from "react";
import { useCart } from "react-use-cart";
import { db } from "auth/FirebaseAuth";
import {
  Card,
  Col,
  message,
  notification,
  Row,
  Button,
  Empty,
  Radio,
} from "antd";
import "./cart.css";
import Utils from "utils";
import AddToBag from "../../../components/shared-components/AddToCart/bag";
import { PayPalButton } from "react-paypal-button-v2";
import {
  PayPalScriptProvider,
  BraintreePayPalButtons,
  PayPalButtons,
} from "@paypal/react-paypal-js";

import firebase from "firebase";
import Loading from "components/shared-components/Loading";
import MyModal from "components/shared-components/MyModal";
import { useHistory } from "react-router-dom";
import moment from "moment";
import DeliveryType from "./deliveryType";
const Cart = (props) => {
  let { userData, user } = props;
  const { addItem, items, removeItem, updateItemQuantity, emptyCart } =
    useCart();
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [cityData, setCityData] = useState({});
  const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [orderLoading, setOrderLoading] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [lastSequance, setlastSequance] = useState(0);
  const [forceRe, setFormRe] = useState(false);
  const [priceLoading, setpriceLoading] = useState(true);
  useEffect(() => {
    if (user === null) {
      history.push("/");
      return;
    }
    let list = [];
    let itemsListener = db.collection("items").onSnapshot((snap) => {
      let subTotall = 0;
      let tax = 0;
      snap.forEach((doc) => {
        let data = checkIfExsist(doc.id, doc.data());
        if (data) {
          list.push({
            ...data,
            productID: doc.id,
            deliveryStatus: "Unavailable",
            deliveryAddress: userData.customerAddresses[0],
          });
          // subTotall +=
          //   data.itemDiscountedPrice < data.itemOriginalPrice &&
          //   data.itemDiscountedPrice !== 0
          //     ? data.itemDiscountedPrice * data.quantity
          //     : data.itemOriginalPrice * data.quantity;
          // tax +=
          //   getTaxes(
          //     data.itemTax,
          //     getPrice(data.itemDiscountedPrice, data.itemOriginalPrice)
          //   ) * data.quantity;
        }
      });
      setTimeout(() => {
        setProducts(list);
        // setTaxes(tax);
        // setSubTotal(subTotall);
        setLoading(false);
      }, 1000);
    });

    let lastSeqListen = db
      .collection("settings")
      .doc("lastOrderNumberSequence")
      .onSnapshot((snap) => {
        setlastSequance(snap.data().value);
      });

    async function getOtherData() {
      let customer = await db.collection("customers").doc(userData.id).get();
      let networks = await db
        .collection("networks")
        .where("country", "==", customer.data().customerAddresses[0].country)
        .get();
      let userNetwork = [];
      networks.forEach((doc) => {
        let currentState = doc.data().stateCity.filter((i) => {
          return i.state === customer.data().customerAddresses[0].province;
        });

        let currentCity = currentState[0].cities.filter((i) => {
          return i.name === customer.data().customerAddresses[0].city;
        });

        setCityData({
          ...currentCity[0],
          address: customer.data().customerAddresses[0].address,
        });
      });
    }
    getOtherData();
    return () => {
      itemsListener();
      lastSeqListen();
    };
  }, []);
  const calcPricing = (snap) => {
    let subTotall = 0;
    let tax = 0;
    snap.map((i) => {
      let data = checkIfExsist(i.id, i);
      if (data) {
        if (
          data.deliveryStatus === "Delivery" ||
          data.deliveryStatus === "Pickup"
        ) {
          subTotall +=
            data.itemDiscountedPrice < data.itemOriginalPrice &&
            data.itemDiscountedPrice !== 0
              ? data.itemDiscountedPrice * data.quantity
              : data.itemOriginalPrice * data.quantity;
          tax +=
            getTaxes(
              data.itemTax,
              getPrice(data.itemDiscountedPrice, data.itemOriginalPrice)
            ) * data.quantity;
        }
      }
    });
    setTaxes(tax);
    setSubTotal(subTotall);
  };
  const checkIfExsist = (id, data) => {
    let filtered = Utils.filterArray(items, "id", id);
    if (Array.isArray(filtered) && filtered.length) {
      return {
        ...data,
        id: id,
        seller: filtered[0].seller,
        stockTotal: data.quantity,

        quantity: filtered[0].quantity,
        updatedAt: filtered[0].updatedAt,
        storeData: filtered[0].storeData,
      };
    } else {
      return false;
    }
  };
  const getTaxes = (tax, price) => {
    tax = parseFloat(tax.split("%")[0]);
    let perc = (price * tax) / 100;

    return perc;
  };
  const onRemoveItem = (id) => {
    let newProductsArray = products.filter((i) => {
      return i.productID !== id;
    });
    removeItem(id);
    let oldItem = products.filter((i) => {
      return i.productID === id;
    });
    if (oldItem[0].deliveryStatus === "Delivery") {
      let price =
        oldItem[0].itemDiscountedPrice < oldItem[0].itemOriginalPrice &&
        oldItem[0].itemDiscountedPrice !== 0
          ? oldItem[0].itemDiscountedPrice * oldItem[0].quantity
          : oldItem[0].itemOriginalPrice * oldItem[0].quantity;

      let tax =
        getTaxes(
          oldItem[0].itemTax,
          getPrice(oldItem[0].itemDiscountedPrice, oldItem[0].itemOriginalPrice)
        ) * oldItem[0].quantity;

      setTaxes((prev) => prev - tax);
      setSubTotal((prev) => prev - price);
    }

    setProducts(newProductsArray);
    return message.info("item removed");
  };
  const onPlus = (id) => {
    setpriceLoading(true);

    let updateIndex = "";
    products.map((item, index) => {
      if (item.productID === id) {
        updateIndex = index;
      }
    });
    let newArray = products;
    newArray[updateIndex].quantity = newArray[updateIndex].quantity + 1;
    if (
      newArray[updateIndex].deliveryStatus === "Delivery" ||
      newArray[updateIndex].deliveryStatus === "Pickup"
    ) {
      let price = parseFloat(
        newArray[updateIndex].itemDiscountedPrice <
          newArray[updateIndex].itemOriginalPrice &&
          newArray[updateIndex].itemDiscountedPrice !== 0
          ? newArray[updateIndex].itemDiscountedPrice
          : newArray[updateIndex].itemOriginalPrice
      );
      let tax =
        getTaxes(
          newArray[updateIndex].itemTax,
          getPrice(
            newArray[updateIndex].itemDiscountedPrice,
            newArray[updateIndex].itemOriginalPrice
          )
        ) * newArray[updateIndex].quantity;

      setSubTotal((prev) => prev + price);
      setTaxes(tax);
    }

    updateItemQuantity(id, newArray[updateIndex].quantity);
    setProducts(newArray);
    setFormRe(true);
    setTimeout(() => {
      setpriceLoading(false);
    }, 500);
  };
  const onMinus = (id) => {
    setpriceLoading(true);
    let updateIndex = "";
    products.map((item, index) => {
      if (item.productID === id) {
        updateIndex = index;
      }
    });
    let newArray = products;
    newArray[updateIndex].quantity = newArray[updateIndex].quantity - 1;
    updateItemQuantity(id, newArray[updateIndex].quantity);
    if (
      newArray[updateIndex].deliveryStatus === "Delivery" ||
      newArray[updateIndex].deliveryStatus === "Pickup"
    ) {
      let price =
        newArray[updateIndex].itemDiscountedPrice <
          newArray[updateIndex].itemOriginalPrice &&
        newArray[updateIndex].itemDiscountedPrice !== 0
          ? newArray[updateIndex].itemDiscountedPrice
          : newArray[updateIndex].itemOriginalPrice;
      let tax =
        getTaxes(
          newArray[updateIndex].itemTax,
          getPrice(
            newArray[updateIndex].itemDiscountedPrice,
            newArray[updateIndex].itemOriginalPrice
          )
        ) * newArray[updateIndex].quantity;

      setTaxes(tax);

      setSubTotal((prev) => prev - price);
    }

    setProducts(newArray);
    setTimeout(() => {
      setpriceLoading(false);
    }, 500);
  };
  const getPrice = (discountedPrice, price) => {
    let p =
      discountedPrice < price && discountedPrice !== 0
        ? discountedPrice
        : price;
    return p;
  };
  const testCreateOrder = async (i, transactionID, seq) => {
    let itemPrice = getPrice(i.itemDiscountedPrice, i.itemOriginalPrice);
    let totalPrice = itemPrice;

    let storePayment = 0;
    let taxAmount = getTaxes(i.itemTax, itemPrice);
    let storeInfo = await db.collection("stores").doc(i.storeID).get();
    let orderServiceFee = 0;
    let key =
      i.deliveryStatus === "Pickup"
        ? "orderPickupAddress"
        : "orderDestinationAddress";
    let addressValue =
      i.deliveryStatus === "Pickup"
        ? {
            address: storeInfo.data().storeAddress,
            city: storeInfo.data().storeCity,
            country: storeInfo.data().storeCountry,
            postalCode: storeInfo.data().storePostalCode,
            province: storeInfo.data().storeProvince,
          }
        : {
            address: userData.customerAddresses[0].address,
            city: userData.customerAddresses[0].city,
            country: userData.customerAddresses[0].country,
            instruction: userData.customerAddresses[0].shippingInstructions,
            postalCode: userData.customerAddresses[0].postalCode,
            province: userData.customerAddresses[0].province,
          };
    let storeReturn = storeInfo.data().returnFee
      ? storeInfo.data().returnFee
      : 0;
    let returnFees = 0;
    let storeFees = storeInfo.data().serviceFee
      ? storeInfo.data().serviceFee
      : 0;

    if (typeof storeFees === "string") {
      storeFees = parseFloat(storeFees.split("%")[0]);
      storePayment = totalPrice - (storeFees * 100) / 100;
      orderServiceFee = (totalPrice * storeFees) / 100;
    } else {
      storePayment = totalPrice - storeFees;
      orderServiceFee = storeFees;
    }
    if (typeof storeReturn === "string") {
      storeReturn = parseFloat(storeReturn.split("%")[0]);
      returnFees = (totalPrice * storeReturn) / 100;
    } else {
      returnFees = totalPrice - storeReturn;
    }
    let order = {
      customerFullName: userData.customerFullName,
      customerID: userData.id,
      customerOrderPaymentStatus:
        i.deliveryStatus === "Pickup" ? "Pending" : "Paid",
      orderStatus: "Pending store approval",
      orderKind: i.deliveryStatus,
      customerReturnPaymentStatus: "Unrefunded",
      itemBarcode: i.itemBarcode,
      itemCategory: i.itemCategory,
      itemCondition: i.itemCondition,
      itemDescription: i.itemDescription,
      itemDiscountedPrice: i.itemDiscountedPrice,
      itemID: i.id,
      itemImage: i.itemImagesUrl[0],
      itemOriginalPrice: i.itemOriginalPrice,
      itemQuantity: i.quantity,
      itemStatus: i.itemStatus,
      itemTax: i.itemTax,
      itemTitle: i.itemTitle,
      networks: {},
      orderCreatedAt: firebase.firestore.FieldValue.serverTimestamp(),

      orderItemPrice: itemPrice,
      orderNetwork: {
        city: userData.customerAddresses[0].city,
        country: userData.customerAddresses[0].country,
        province: userData.customerAddresses[0].province,
      },
      orderQuantity: i.quantity,
      orderTax: taxAmount,
      orderSubTotalPrice: parseFloat(itemPrice) + parseFloat(taxAmount),
      orderServiceFee: orderServiceFee,
      orderStorePayment: itemPrice + taxAmount - orderServiceFee,
      storeOrderPaymentStatus: "Hold",
      transactionID: transactionID,
      serviceFee: storeFees,
      storeID: storeInfo.id,
      storeName: storeInfo.data().storeName,
    };
    order[key] = addressValue;
    let orderNumber = ` O${moment().format("DDMMYYYY")}${seq}`;

    await db.collection("orders").doc(orderNumber).set(order);
  };
  const getSeq = (i) => {
    let seqNum = lastSequance + i;

    return seqNum;
  };
  const createOrder = async (i, transactionID) => {
    let orderNumber = "O" + Math.floor(100000 + Math.random() * 900000000000);
    let price = getPrice(i.discountedPrice, i.price);
    let totalPrice = price * i.quantity;
    let storePayment = 0;
    let storeInfo = await db.collection("stores").doc(i.store_id).get();

    let storeFees = storeInfo.data().fees;

    if (typeof storeFees === "string") {
      storeFees = parseFloat(storeFees.split("%")[0]);
      storePayment = totalPrice - (storeFees * 100) / 100;
    } else {
      storePayment = totalPrice - storeFees;
    }
    let order = {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      orderStatus: "Pending store approval",
      orderDestinationAddress: {
        Address: cityData.address,
        City: userData.addresses[0].city,
        Province: userData.addresses[0].province,
        Country: userData.addresses[0].country,
        Instruction: userData.addresses[0].shippingInstructions,
      },
      orderQuantity: i.quantity,
      orderItemPrice: price,
      orderTax: "",
      orderSubTotalPrice: totalPrice,
      serviceFee: "",
      orderStorePayment: storePayment,
      orderPaymentStatus: {
        Customer: "Paid",
        Store: "Hold",
      },
      TransactionID: transactionID,
      itemImage: i.imagesUrl[0],
      itemID: i.id,
      itemTitle: i.title,
      itemBarcode: i.barcode,
      itemCondition: i.condition,
      itemCategory: i.category,
      itemPrice: i.price,
      itemDiscountedPrice: i.discountedPrice,
      storeID: storeInfo.id,
      storeName: storeInfo.data().storeName,
      customerID: userData.id,
      customerName: userData.fullName,
    };
    db.collection("orders").doc(orderNumber).set(order);
  };
  const onPayment = async (transactionID) => {
    setOrderLoading(true);

    const resultArray = await Promise.all(
      products.map(
        async (i, index) =>
          await testCreateOrder(i, transactionID, getSeq(index + 1))
      )
    );
    db.collection("settings")
      .doc("lastOrderNumberSequence")
      .update({
        value: firebase.firestore.FieldValue.increment(products.length),
      });
    emptyCart();
    setProducts([]);
    setSubTotal(0);
    setTaxes(0);
    setOrderLoading(false);
    notification["success"]({
      message: "Order Created",
      description: `Your order is created successfuly, we are working on it to deliver it as soon as possible`,
    });
  };
  const initialOptions = {
    "client-id":
      "AbZQSsGkuc_UVxWes4Q23kqBEaWghD6r6RDDXNvbwQ-CRty2BWXzvsxQG5Oj0oDA2V4vVbjVzsRfgZKh",
    currency: "USD",
    intent: "capture",
    "data-client-token": "abc123xyz==",
  };
  const onChangeDeliveryStatus = (itemID, deliveryType, address) => {
    setpriceLoading(true);
    let newArray = products;
    newArray.forEach(function (i, index) {
      if (i.id === itemID) {
        newArray[index].deliveryStatus = deliveryType;
        newArray[index].deliveryAddress = address;
      }
    });

    calcPricing(newArray);
    setProducts(newArray);
    setTimeout(() => {
      setpriceLoading(false);
    }, 500);
  };
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loading size="large" />
      </div>
    );
  }

  return (
    <div
      style={{
        marginTop: 100,
      }}
    >
      {orderLoading && (
        <div className="lay">
          <div className="layCont">
            <div>
              <Loading size="large" />
              <span
                className="text-center"
                style={{ display: "block", color: "white", fontSize: 25 }}
              >
                Working on your order
              </span>
            </div>
          </div>
        </div>
      )}
      {products.length === 0 ? (
        <Empty
          image="https://firebasestorage.googleapis.com/v0/b/aspect-e0f56.appspot.com/o/assets%2F172-1723816_trolley-clipart-shopping-cart-shopping-cart-icon-png-removebg.png?alt=media&token=034c436c-20ee-4300-acc7-dee8744c8d4b"
          imageStyle={{
            height: 150,
          }}
          description={
            <span style={{ fontWeight: "bold", fontSize: 18 }}>
              Your shopping cart is empty
            </span>
          }
        >
          <Button
            type="primary"
            onClick={() => {
              history.push("/");
            }}
            size="large"
          >
            Shop now
          </Button>
        </Empty>
      ) : (
        <Row className="row " style={{ width: "95%", margin: "auto" }}>
          <Col flex="1 0 70%" className="column cartPaddingRight ">
            <MyModal
              show={showProductModal}
              showCart={false}
              handleClose={() => {
                setShowProductModal(false);
              }}
              address={userData ? userData.customerAddresses[0] : ""}
              details={selectedProduct}
            />

            {products.map((i) => {
              console.log(i)
              return (
                <div className="add">
                  <Card key={i.id} style={{ flex: 1 }}>
                    <Row className="row ">
                      <Col flex="1 0 35%" className="column cartPaddingRight">
                        <img
                          width="100%"
                          height="180"
                          src={i.itemImagesUrl[0]}
                        />
                      </Col>

                      <Col flex="1 0 65%" className="column  ">
                        <div style={{ cursor: "pointer" }}>
                          <div
                            onClick={() => {
                              setSelectedProduct(i);
                              setShowProductModal(true);
                            }}
                          >
                            <h2 style={{ color: "black" }}>{i.itemTitle}</h2>
                          </div>
                          <div>
                            <h2 style={{ color: "black" }}>
                              CAD$
                              {i.itemDiscountedPrice < i.itemOriginalPrice &&
                              i.itemDiscountedPrice !== 0
                                ? i.itemDiscountedPrice
                                : i.itemOriginalPrice}
                            </h2>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div style={{ flex: 1 }}>
                              <h4>Seller: {i.seller}</h4>
                            </div>
                          </div>
                          {/* <div style={{ display: "flex" }}>
                        <h2>
                          Total CAD$
                          {i.itemDiscountedPrice < i.itemOriginalPrice &&
                          i.itemDiscountedPrice !== 0
                            ? i.itemDiscountedPrice * i.quantity
                            : i.itemOriginalPrice * i.quantity}
                        </h2>
                      </div> */}
                          <div>
                            <AddToBag
                              details={i}
                              address={userData.customerAddresses[0]}
                              onRemove={onRemoveItem}
                              amount={i.quantity}
                              onPlus={onPlus}
                              onMinus={onMinus}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                  <div style={{ marginLeft: 5,flex:0.5 }}>
                    <DeliveryType
                      onStatusChange={onChangeDeliveryStatus}
                      storeID={i.storeID}
                      itemID={i.id}
                      userID={userData.id}
                    />
                    <Card
                      title={
                        i.deliveryStatus === "Pickup"
                          ? "Pickup at"
                          : "Delivery to"
                      }
                      style={{ flex: 0.6 }}
                    >
                      <h4> {`Customer: ${userData.customerFullName}`}</h4>
                      <h4>
                        Address:{" "}
                        {i.deliveryStatus === "Pickup"
                          ? i.storeData.storeAddress
                          : cityData.address}{" "}
                      </h4>
                      <div style={{ display: "flex" }}>
                        <h4 style={{ flex: 1 }}>
                          City:{" "}
                          {i.deliveryStatus === "Pickup"
                            ? i.storeData.storeCity
                            : userData.customerAddresses[0].city}
                        </h4>
                        <h4 style={{ flex: 1 }}>
                          Province:{" "}
                          {i.deliveryStatus === "Pickup"
                            ? i.storeData.storeProvince
                            : userData.customerAddresses[0].province}
                        </h4>
                      </div>
                      <div style={{ display: "flex" }}>
                        <h4 style={{ flex: 1 }}>
                          Country:{" "}
                          {i.deliveryStatus === "Pickup"
                            ? i.storeData.storeCountry
                            : userData.customerAddresses[0].country}
                        </h4>
                        <h4 style={{ flex: 1 }}>
                          Postal Code:{" "}
                          {i.deliveryStatus === "Pickup"
                            ? i.storeData.storePostalCode
                            : userData.customerAddresses[0].postalCode}
                        </h4>
                      </div>
                      {i.deliveryStatus === "Delivery" && (
                        <h4>
                          Instructions:{" "}
                          {userData.customerAddresses[0].shippingInstructions}
                        </h4>
                      )}
                    </Card>
                  </div>
                </div>
              );
            })}
          </Col>
          {products.length !== 0 && (
            <Col flex="1 0 10%" className="column ">
              {/* <Card title={<h2>Shipping: {cityData.shipping_speed}</h2>}></Card> */}

              <Card>
                {priceLoading ? (
                  <Loading />
                ) : (
                  <div>
                    <h5>Sub-total: $CAD{subTotal}</h5>
                    <h5>Taxes: $CAD{taxes}</h5>
                    <h5>Delivery: 0</h5>
                    <h2>Order Total: $CAD{subTotal + taxes}</h2>
                    {subTotal !== 0 && (
                      <div style={{}}>
                        <PayPalScriptProvider
                          options={{
                            "client-id":
                              "AbZQSsGkuc_UVxWes4Q23kqBEaWghD6r6RDDXNvbwQ-CRty2BWXzvsxQG5Oj0oDA2V4vVbjVzsRfgZKh",

                            currency: "CAD",
                          }}
                        >
                          <PayPalButtons
                            forceReRender={[subTotal]}
                            style={{
                              color: "blue",
                              shape: "pill",
                              label: "pay",
                              height: 40,
                            }}
                            createOrder={(data, actions) => {
                              return actions.order.create({
                                purchase_units: [
                                  {
                                    amount: {
                                      value: subTotal + taxes,
                                    },
                                  },
                                ],
                              });
                            }}
                            onApprove={(details, data) => {
                              onPayment(details.orderID);
                              notification["success"]({
                                message: "Payment Successful",
                                description: `Relax while we confirm & deliver your order`,
                              });

                              // OPTIONAL: Call your server to save the transaction
                            }}
                          />
                          ;{" "}
                        </PayPalScriptProvider>

                        {/* <PayPalButton
                      amount={subTotal + taxes}
                      currency="CAD"
                      // options={{ currency:"CAD" }}
                      // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                      onSuccess={(details, data) => {
                        onPayment(details.id);
                        notification["success"]({
                          message: "Payment Successful",
                          description: `Relax while we confirm & deliver your order`,
                        });

                        // OPTIONAL: Call your server to save the transaction
                      }}
                      options={{
                        clientId:
                          "AbZQSsGkuc_UVxWes4Q23kqBEaWghD6r6RDDXNvbwQ-CRty2BWXzvsxQG5Oj0oDA2V4vVbjVzsRfgZKh",
                      }}
                    /> */}
                      </div>
                    )}
                    {subTotal === 0 && products.length !== 0 ? (
                      <Button
                        type="primary"
                        block
                        onClick={() => {
                          onPayment(Math.floor(Math.random() * 10000));
                        }}
                      >
                        Create Order
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Card>
            </Col>
          )}
        </Row>
      )}
    </div>
  );
};
export default Cart;
