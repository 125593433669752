import React, { useEffect, useState } from "react";
import { Radio, Spin, Select, Card } from "antd";
import Loading from "components/shared-components/Loading";
import { db } from "auth/FirebaseAuth";
const { Option, OptGroup } = Select;

const DeliveryType = ({ storeID, onStatusChange, itemID, userID }) => {
  const [loading, setloading] = useState(true);
  const [deliveryStatus, setdeliveryStatus] = useState(false);
    const [pickupstatus, setpickupstatus] = useState(false);
  const [selectedStatus, setselectedStatus] = useState("");
  const [address, setaddress] = useState([]);
  const [selectedAress, setselectedAress] = useState("")
  useEffect(() => {
    async function getStoreData(params) {
      let storeInfo = await db.collection("stores").doc(storeID).get();
      let addressInfo = await db.collection("customers").doc(userID).get();

      setaddress(addressInfo.data().customerAddresses);
      setselectedAress(0);
      setdeliveryStatus(
        storeInfo.data().storeDeliveryServiceStatus === "Active" ? true : false
      );
      setpickupstatus(
        storeInfo.data().storePickupServiceStatus === "Active" ? true : false
      );
      let status =
        storeInfo.data().storeDeliveryServiceStatus === "Active"
          ? "Delivery"
          : storeInfo.data().storePickupServiceStatus === "Active"
          ? "Pickup"
          : "Unavailable";
        onStatusChange(itemID, status);
        setselectedStatus(status)
      setTimeout(() => {
        setloading(false);
      }, 1000);
    }
    getStoreData();
  }, []);
   const onSelectedStatusChange = (e) => {
       setselectedStatus(e.target.value);
       onStatusChange(itemID, e.target.value);
    }
  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        <Card>
            <Radio.Group
              style={{ alignItems:'center',justifyContent:'center' }}
            defaultValue={!deliveryStatus ? "Pickup" : "Delivery"}
            onChange={onSelectedStatusChange}
          >
            <Radio
              value="Delivery"
              disabled={!deliveryStatus}
            >
              Delivery
            </Radio>
            <Radio
              value="Pickup"
              disabled={!pickupstatus}
            >
              Pickup
            </Radio>
          </Radio.Group>
          {/* <div className="mt-2">
            <span style={{ fontSize: 16, fontWeight: "500" }}>
              Delivery Address
            </span>
            <Select
              className="mt-1"
              value={selectedAress}
              style={{ width: "100%" }}
            >
              <OptGroup label="Main Address">
                <Option value={0}>
                  {address[0].country}/{address[0].province}/{address[0].city}:{" "}
                  {address[0].address}
                </Option>
              </OptGroup>
              <OptGroup label="Other Address">
                {address.map((i, index) => {
                  if (index === 0) {
                    return;
                  } else {
                    return (
                      <Option value={index}>
                        {i.country}/{i.province}/{i.city}: {i.address}
                      </Option>
                    );
                  }
                })}
              </OptGroup>
            </Select>
          </div> */}
        </Card>
      )}
    </div>
  );
};

export default DeliveryType;
